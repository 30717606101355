@font-face
  font-family: 'tketypebold'
  src: url('../assets/fonts/tke_type/TKEType-Bold.woff2') format('woff2'), url('../assets/fonts/tke_type/TKEType-Bold.woff') format('woff')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'tketypeitalic'
  src: url('../assets/fonts/tke_type/TKEType-RegularItalic.woff2') format('woff2'), url('../assets/fonts/tke_type/TKEType-RegularItalic.woff') format('woff')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'tketypemedium'
  src: url('../assets/fonts/tke_type/TKEType-Medium.woff2') format('woff2'), url('../assets/fonts/tke_type/TKEType-Medium.woff') format('woff')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'tketyperegular'
  src: url('../assets/fonts/tke_type/TKEType-Regular.woff2') format('woff2'), url('../assets/fonts/tke_type/TKEType-Regular.woff') format('woff')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'tkemonoregular'
  src: url('../assets/fonts/tke_mono/TKETypeMono-Regular.woff2') format('woff2'), url('../assets/fonts/tke_mono/TKETypeMono-Regular.woff') format('woff')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'tkemonolight'
  src: url('../assets/fonts/tke_mono/TKETypeMono-Light.woff2') format('woff2'), url('../assets/fonts/tke_mono/TKETypeMono-Light.woff') format('woff')
  font-weight: normal
  font-style: normal