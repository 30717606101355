html
  height: 100%
  font-size: 100%

body
  height: 100%
  font-size: 100%
  background-color: $black
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

.section
  padding: 1rem 0rem

.footer
  padding-left: 0
  padding-right: 0

.container
  padding: 0 $content-padding-mobile

  .content

    h1
      font-family: $family-mono-light
      font-size: $size-2

    h2
      font-family: $family-sans-serif
      font-size: $size-3

    p,
    li
      font-size: $size-4
      line-height: 1.6em

.fieldlabel
  font-family: $family-sans-serif-bold
  line-height: 1.6em
  font-size: $size-4
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.unit
  font-family: $family-sans-serif
  font-size: $size-6
  color: $text

.mobile-menu
  position: fixed
  display: block
  top: 0
  left: 0
  width: 20rem
  max-width: 100%
  height: 100%
  background-color: $white
  transition: transform .3s ease // when changing time also change timeout in SliderInput
  transform: translate3d(-100%,0,0)
  overflow-y: scroll
  z-index: $z-index-menu

  &.is-visible
    transform: translate3d(0,0,0)

    .toolbar
      margin-left: -$content-padding-mobile
      margin-right: -$content-padding-mobile
      padding: 0.7rem 0
      height: $toolbar-height-mobile
      background-color: $primary
      color: $text-invert

      .icon-button
        position: absolute
        top: 0.7rem

        &.close
          right: $content-padding-mobile

        &.settings
          left: $content-padding-mobile

+tablet
  html
    font-size: 125%

  body
    font-size: 125%

  .section
    padding: 2rem 1rem

  .container
    width: 100%
    max-width: $content-width-desktop
    padding: 0 $content-padding-desktop

  .fieldlabel
    line-height: 1.6em
    font-size: $size-5
    font-family: $family-mono-light
    font-size: $size-6
    text-transform: uppercase

  .unit
    font-size: $size-7
    font-family: $family-mono-light
    text-transform: uppercase

  .mobile-menu
    position: relative
    display: block
    top: auto
    right: auto
    left: auto
    height: auto
    width: inherit
    background-color: transparent
    transition: none
    transform: none
    overflow-y: visible
    z-index: $z-index-menu

    .toolbar
      display: none